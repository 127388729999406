import React from "react";
import Providers from "../assets/providers_tf.png";
import VPC from "../assets/vpc.png";
import Subnet from "../assets/subnet.png";
import Gateway from "../assets/gateway.png";
import Route_Table from "../assets/route_table.png";
import Security from "../assets/security.png";
import Datasource from "../assets/datasource.png";
import Key from "../assets/key_pair.png";
import EC2 from "../assets/ec2.png";
import Script from "../assets/script.png";
import Terminal from "../assets/terminal.png";

const Tera = () => {
  return (
    <div
      name="Projects"
      className="flex w-full h-full bg-[#000] text-[#fff] pt-60"
    >
      <div className="max-w-[1000px] mx-auto p-4 flex flex-col w-full h-full">
        <div className="pb-8 text-left">
          {/* Cloud Resume Challenge Container */}
          <h1 className="text-3xl my-5 text-center">
            Terraform & AWS Development Environment
          </h1>
          <h2 className="text-2xl my-5 border-b-2 border-green-400 inline">
            About:
          </h2>
          <p className="text-2xl my-5 ">
            In this project I use Terraform to build a development environment
            in AWS. A developer environment is a workspace for developers that
            allows them to test changes to their code without the risk of
            breaking anything in a live environment. This project includes
            creating a VPC, Internet Gateway, Public Route Table, Public
            Security Group, Public Subnet, and an EC2 instance using Terraform.
            If you would like to see the code for this project please visit{" "}
            <a
              href="https://github.com/Colin-Powers/Development-Environment"
              target="_blank"
              className="text-green-400"
            >
              here.
            </a>
          </p>
          <h2 className="text-2xl my-5 border-b-2 border-green-400 inline">
            Set Up:
          </h2>
          <p className="text-2xl my-5">
            Before I began to create the Terraform IaC for this project I
            created an IAM user with the keys and permissions necessary to
            deploy AWS resources and then linked my Virtual Studio Code to that
            user through the AWS extension.
          </p>
          <h2 className="text-2xl my-5 border-b-2 border-green-400 inline">
            Linking to a Provider:
          </h2>
          <p className="text-2xl my-5">
            After I finished setting up my Virtual Studio Code IAM credentials I
            moved on to creating the providers.tf file. The providers.tf file
            allows Terraform to interact with an API, in this case AWS.
            <img src={Providers} alt="providers.tf file" className="my-5" />
            Once my providers.tf file was finished, I ran terraform init in the
            terminal which initializes a working directory.
          </p>
          <h2 className="text-2xl my-5 border-b-2 border-green-400 inline">
            Creating a VPC:
          </h2>
          <p className="text-2xl my-5">
            After initializing my working directory and letting Terraform know
            which provider API to work with I moved on to creating the actual
            AWS resources. I started with creating a VPC, a virtual network,
            that my development environment will reside in.
            <img src={VPC} alt="vpc.tf file" className="my-5" />
          </p>
          <h2 className="text-2xl my-5 border-b-2 border-green-400 inline">
            Creating a Subnet:
          </h2>
          <p className="text-2xl my-5">
            Next I created a public subnet within the VPC. A subnet is a mini
            network nested inside of a larger network. In this case I made the
            subnet public so that resources I deploy inside of the subnet can
            connect to other networks and the Internet.
            <img src={Subnet} alt="subnet.tf file" className="my-5" />
          </p>
          <h2 className="text-2xl my-5 border-b-2 border-green-400 inline">
            Creating an Internet Gateway:
          </h2>
          <p className="text-2xl my-5">
            In order for the resources in a public subnet to connect to the
            internet it needs to be connected to an internet gateway.
            <img src={Gateway} alt="gateway.tf file" className="my-5" />
          </p>
          <h2 className="text-2xl my-5 border-b-2 border-green-400 inline">
            Creating a Route Table:
          </h2>
          <p className="text-2xl my-5">
            For the traffic in the virtual network I created to get directed to
            the correct location I created a route table. Along with creating
            the route table I also made a custom route that directs all IP
            addresses to the internet gateway. I also created a route table
            association that links my new route table with my public subnet.
            <img src={Route_Table} alt="route.tf file" className="my-5" />
          </p>
          <h2 className="text-2xl my-5 border-b-2 border-green-400 inline">
            Creating a Security Group:
          </h2>
          <p className="text-2xl my-5">
            To establish customized control over the traffic that is allowed to
            reach and leave the resources in my VPC I created a security group
            which acts as a firewall. This new security group only allows
            inbound ssh access from my IP address and allows all forms of
            outbound traffic.
            <img src={Security} alt="security.tf file" className="my-5" />
          </p>
          <h2 className="text-2xl my-5 border-b-2 border-green-400 inline">
            Creating an AMI Datasource:
          </h2>
          <p className="text-2xl my-5">
            Before launching an EC2 instance I needed to let Terraform know what
            Amazon Machine Image datasource I intended to use, Ubuntu 22.04 in
            this case.
            <img src={Datasource} alt="datasource.tf file" className="my-5" />
          </p>
          <h2 className="text-2xl my-5 border-b-2 border-green-400 inline">
            Creating a Key Pair:
          </h2>
          <p className="text-2xl my-5">
            To allow myself to securely SSH into the Ubuntu instance I created a
            key pair using the ssh-keygen command and then stored the public key
            in AWS using Terraform.
            <img src={Key} alt="key.tf file" className="my-5" />
          </p>
          <h2 className="text-2xl my-5 border-b-2 border-green-400 inline">
            Creating an EC2 Instance:
          </h2>
          <p className="text-2xl my-5">
            At this point the actual EC2 instance is ready to be created.
            <img src={EC2} alt="ec2.tf file" className="my-5" />I also included
            the following bash script that updates the instance and installs
            Docker.
            <img src={Script} alt="ec2.tf file" className="my-5" />
            Finally, I also included a provisioner that allows for me to ssh
            directly into the development environment from VSCode.
          </p>
          <h2 className="text-2xl my-5 border-b-2 border-green-400 inline">
            Conclusion:
          </h2>
          <p className="text-2xl my-5">
            By using a terraform.tfvars file and using variables in my code I
            created a template that can be used to automate customized
            development environments depending on the needs of the user.
            <img
              src={Terminal}
              alt="development environment"
              className="my-5"
            />
          </p>
        </div>
      </div>
    </div>
  );
};

export default Tera;
