import React from "react";
import { FaGithub, FaLinkedin } from "react-icons/fa";
import { BsFacebook } from "react-icons/bs";
import { SiEtsy } from "react-icons/si";

const Footer = () => {
  return (
    <footer class="bg-[#000] text-center items-center justify-center text-white text-2xl pt-20">
      <div>
        <p>powers.golden@gmail.com</p>
      </div>
      <div>
        <ul className="flex justify-center items-center py-5">
          <li>
            <a
              target="_blank"
              className="flex justify-between items-center w-full text-[#fff]"
              href="https://github.com/Colin-Powers"
            >
              <FaGithub size={30} />
            </a>
          </li>
          <li>
            {" "}
            <a
              className="flex justify-between items-center w-full text-[#fff] py-1"
              href="https://www.linkedin.com/in/cm-powers/"
              target="_blank"
            >
              <FaLinkedin size={30} />
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
