import React from "react";
import Python from "../assets/python.png";
import HTML from "../assets/html.png";
import CSS from "../assets/css.png";
import JavaScript from "../assets/javascript.png";
import ReactImg from "../assets/react.png";
import Linux from "../assets/LinuxEssentials.png";
import Tailwind from "../assets/tailwind.png";
import AWS from "../assets/aws.png";
import Cloud_Pract from "../assets/Cloud_Pract.png";
import Sol_Arch from "../assets/Sol_Arch.png";
import terraform from "../assets/terraform.png";
import Tableau from "../assets/tableau.png";
import Excel from "../assets/excel.png";
import SQL from "../assets/sql.png";
import { HiMenuAlt1 } from "react-icons/hi";

const Skills = () => {
  return (
    <div className="w-full h-full sm:h-screen bg-[#000] text-[#fff]">
      <div className="max-w-[1000px] mx-auto p-4 flex flex-col sm:justify-center w-full h-full">
        <div className="text-center pt-20">
          <h1 className="text-4xl font-bold inline border-b-4 border-green-400 text-[#fff]">
            Skills / Certifications
          </h1>
        </div>
        {/* Icon Container */}
        <div className="w-full grid grid-cols-2 sm:grid-cols-3 gap-4 text-center py-8">
          {/* HTML Icon */}
          <div className="shadow-md shadow-green-400 hover:scale-110 duration-500">
            <a href="/Cloud">
              <img className="w-20 mx-auto" src={HTML} alt="HTML Logo" />
              <p>HTML</p>
            </a>
          </div>
          {/* CSS Icon */}
          <div className="shadow-md shadow-green-400 hover:scale-110 duration-500">
            <a href="/Cloud">
              <img className="w-20 mx-auto" src={CSS} alt="CSS Logo" />
              <p>CSS</p>
            </a>
          </div>
          {/* JavaScript Icon */}
          <div className="shadow-md shadow-green-400 hover:scale-110 duration-500">
            <a href="/Cloud">
              <img
                className="w-20 mx-auto"
                src={JavaScript}
                alt="JavaScript Logo"
              />
              <p>JavaScript</p>
            </a>
          </div>
          {/* React Icon */}
          <div className="shadow-md shadow-green-400 hover:scale-110 duration-500">
            <a href="/Web">
              <img className="w-20 mx-auto" src={ReactImg} alt="React Logo" />
              <p>React</p>
            </a>
          </div>
          {/* Tailwind Icon */}
          <div className="shadow-md shadow-green-400 hover:scale-110 duration-500">
            <a href="/Web">
              <img
                className="w-20 mx-auto"
                src={Tailwind}
                alt="Tailwind Logo"
              />
              <p>Tailwind</p>
            </a>
          </div>
          {/* Python Icon */}
          <div className="shadow-md shadow-green-400 hover:scale-110 duration-500">
            <a href="/Python">
              <img className="w-20 mx-auto" src={Python} alt="Python Logo" />
              <p>Python</p>
            </a>
          </div>

          {/* Linux Essentials Icon */}
          <div className="shadow-md shadow-green-400 hover:scale-110 duration-500">
            <a href="/Cloud">
              <img className="w-20 mx-auto" src={Linux} alt="HTML Logo" />
              <p>LPI Linux Essentials</p>
            </a>
          </div>
          {/* Cloud Practitioner Icon */}
          <div className="shadow-md shadow-green-400 hover:scale-110 duration-500">
            <a href="/Cloud">
              <img className="w-20 mx-auto" src={Cloud_Pract} alt="HTML Logo" />
              <p>AWS Certified Cloud Practitioner</p>
            </a>
          </div>
          {/* Solutions Architect Icon */}
          <div className="shadow-md shadow-green-400 hover:scale-110 duration-500">
            <a href="/Cloud">
              <img className="w-20 mx-auto" src={Sol_Arch} alt="HTML Logo" />
              <p>AWS Certified Solutions Architect - Associate</p>
            </a>
          </div>
          {/* Terraform Icon */}
          <div className="shadow-md shadow-green-400 hover:scale-110 duration-500">
            <a href="/Cloud">
              <img className="w-20 mx-auto" src={terraform} alt="HTML Logo" />
              <p>HashiCorp Terraform Certified Associate</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skills;
